.nav-link{
    color: skyblue;
    text-align: center;
    padding-left: 15px;
}

.nav-link:hover{
    color: skyblue;
}

.nav-link:active{
    color: skyblue;
    text-decoration: underline;
}

.nav-link:visited{
    color: skyblue;
    text-decoration: underline;
}

ul li{
    padding-left: 30px;
    padding-right: 10px;
}

.v20{
    width: 8%;
    height: 5vh;
    margin-top: 15px;
}


    .last{
        padding-left: 25px;
    }    


@media (max-width: 767px) {
    .v20{
        width: 15%;
        height: 8vh;
    }

    .last{
        text-align: center;
        padding-left: 0px;
    }
  }

@media (max-width: 480px) {
    .v20{
      width: 15%;
      height: 8vh;
    }

    .last{
        text-align: center;
        padding-left: 0px;
    }
}
.nav-item{
    text-align: center;
    background-color: black;
}
.paddbot{
    padding-bottom: 3rem;
}
.colored{
    background: #000;
    color: #fff;
    padding: 10px;
}

.sizer{
    font-size: 3rem;
}

