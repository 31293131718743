footer{
    position: relative;
    width: 100%;
    background: #47E3FF;
    min-height: 10px;
    padding: 2px 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

footer .social-icon{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
    flex-wrap: wrap;
}

footer .social-icon li{
    list-style: none;
}

footer .social-icon li a{
    font-size: 2em;
    color: #fff;
    margin: 0 10px;
    display: inline-block;
    transition: 0.5s;
}

footer .social-icon li .company{
    color: #fff;
    text-align: center;
    margin-bottom: 5px;
    font-size: 1.1em;
    transition: 0.5s;
}

footer .social-icon li a:hover{
    transform: translateY(-10px);
}
.socialicon{
    padding-left: 0px;
}

.socialicon1{
    padding-left: 0px;
}

footer p{
    color: #fff;
    text-align: center;
    margin-bottom: 5px;
    font-size: 1.1em;
}

footer p a{
    color: #fff;
}

footer p a:hover{
    transform: translateY(-10px);
}

footer .wave{
    position: absolute;
    top: -10px;
    left: 0;
    width: 100%;
    height: 10px;
    background: url("../assets/wave.png");
    background-size: 1000px 100px;
}

footer .wave#wave1{
    z-index: 1000;
    opacity: 1;
    bottom: 0;
    animation: animateWave 4s linear infinite;
}

footer .wave#wave2{
    z-index: 999;
    opacity: 0.5;
    bottom: 10px;
    animation: animateWave-2 4s linear infinite;
}

footer .wave#wave3{
    z-index: 1000;
    opacity: 1;
    bottom: 15px;
    animation: animateWave 3s linear infinite;
}

footer .wave#wave4{
    z-index: 999;
    opacity: 0.7;
    bottom: 20px;
    animation: animateWave-2 3s linear infinite;
}

@keyframes animateWave{
    0%{
        background-position-x: 1000px;
    }
    100%{
        background-position-x: 0px;
    }
}

@keyframes animateWave-2{
    0%{
        background-position-x: 0px;
    }
    100%{
        background-position-x: 1000px;
    }
}
