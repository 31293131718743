.navbar{
    padding: 20px;
    margin-bottom: 10px;
}
.logo{
  width: 50vh;
  height: 105vh;
  position: absolute;
  top: 0;
}
.loder{
  color: #fff;
  text-align: center;
  margin-bottom: 5px;
  font-size: 1.1em;
  font-weight: 600;
  text-decoration: none;
}

.down{
  position: relative;
  right: 270px;
  top: 510px;
  float: right;
  font-size: 1.5em;
}

.hider{
  display: none;
}

.banner1{
  background-image: url('../assets/mainpage.png');
  background-size: contain;
  background-position: center;
  background-position-y: 45px;
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  object-fit: fill;
  }

  @media (max-width: 979px) {
    .banner1{
      height: 46vh;
    }

    .down{
      position: relative;
      right: 156px;
      top: 230px;
      font-size: 1em;
      float: right;
    }
  }
  
  @media (max-width: 767px) {
    .banner1{
      height: 46vh;
    }

    .down{
      position: relative;
      right: 134px;
      top: 230px;
      float: right;
      font-size: 0.7em;
    }
  }

  @media (max-width: 600px) {
    .banner1{
      height: 45vh;
    }

    .down{
      position: relative;
      right: 35px;
      top: 223px;
      float: right;
      font-size: 0.7em;
    }
  }
  
  @media (max-width: 500px) {
    .banner1{
      height: 35vh;
    }

    .down{
      position: relative;
      right: 2px;
      top: 194px;
      float: right;
      font-size: 0.6em;
    }
  }

  @media (max-width: 400px) {
    .banner1{
      height: 35vh;
    }

    .down{
      position: relative;
      right: 40px;
      top: 190px;
      float: right;
    }

    .loder{
      font-size: 0.8em;
    }

    .imager{
      width: 35px;
      height: 20px;
    }
  }

  
  @keyframes color{
    0%{
      background-position: 0 50%;
    }
    50%{
      background-position: 100% 50%;
    }
    100%{
      background-position: 0 50%;
    }
  }

  .tube{
    position: relative;
    color: #0e3742;
    text-transform: uppercase;
    animation: animate 5s linear infinite;
  }

  @keyframes animate{
    0%,18%,20%,50.1%,60%,65.1%,80%,90.1%,92%{
      color: #0e3742;
      text-shadow: none;
    }
    18.1%,20.1%,30%,50%,60.1%,65%,80.1%,90%,92.1%,100%{
      color: #fff;
      text-shadow: 0 0 10px #03bcf4,
                    0 0 20px #03bcf4,
                    0 0 40px #03bcf4,
                    0 0 80px #03bcf4,
                    0 0 160px #03bcf4;
    }
  }