.spon{
    width: 25%;
    height: 25%;
}

.sizy{
    padding-top: 50px;
    width: 100%;
    height: 30%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}