.aboutcenter{
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
    padding-top: 50px;
}

.container{
    padding: 0px 0px;
}

.cards{
    border-left: 4px solid;
    padding: 10px;
    margin-left: 15px;
    border-color: linear-gradient(45deg, #d2001a, #7462ff, #f48e21, #23d5ab);
}

.cardee{
    margin-bottom: 15px;
}

.company{
    color: #fff;
    text-align: center;
    margin-bottom: 5px;
    font-size: 1.1em;
}
