body{
  background-color: black;
  color: whitesmoke;
  font-family: 'Oxanium', cursive;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.align{
  display: flex;
  flex-direction: column;
}

section{
  height: 100vh;
}
