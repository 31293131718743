

.container{
    padding: 0px 0px;
}

.mar{
    padding-top: 70px;
}

.gallerycenter{
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}

img{
    max-width: 100%;
}
.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    height: 200px;
  }
  
.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.SwiperSlide{
    width: 50%;
    height: 50%;
}

.paddbot{
    padding-bottom: 3rem;
}

.mySwiper{
    padding: 10px;
}

.swiper-pagination{
    margin-top: 50px;
}

