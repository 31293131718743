form {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    font-size: 16px;
}

input {
    width: 100%;
    height: 35px;
    padding: 7px;
    outline: none;
    border-radius: 5px;
    border: 1px solid rgb(220, 220, 220);
}

.card{
    background-color: black;
}

.button{
    position: relative;
    border: 2px solid skyblue;
    color: #000;
    margin: 20px;
    text-transform: uppercase;
    font-weight: 500;
    text-decoration: none;
    padding: 15px 30px;
    display: inline-flex;
    letter-spacing: 2px;
    transition: 0.5s;
}

.button:hover{
    color: #000;
}

.button:nth-child(2){
    filter: hue-rotate(80deg);
}

.button::before{
    content: '';
    position: absolute;
    left: -20px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 2px;
    background: #47E3FF;
    box-shadow: 5px -8px 0 #47E3FF
                5px 8px 0 #47E3FF;
    transition: width 0.5s, left 0.5s, height 0.5s, box-shadow 0.5s;
    transition-delay: 1s, 0.5s, 0s,0s;
}

.button:hover::before{
    width: 60%;
    height: 100%;
    left: -2px;
    box-shadow: 5px 0 0 #47E3FF
                5px 0 0#47E3FF;
    transition-delay: 0s,0.5s,1s,1s;
}

.button::after{
    content: '';
    position: absolute;
    right: -20px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 2px;
    background: skyblue;
    box-shadow: 5px -8px 0 #47E3FF
                5px 8px 0 #47E3FF;
    transition: width 0.5s, left 0.5s, height 0.5s, box-shadow 0.5s;
    transition-delay: 1s, 0.5s, 0s,0s;
}

.button:hover::after{
    width: 60%;
    height: 100%;
    right: -2px;
    box-shadow: 5px 0 0 #47E3FF
                5px 0 0 #47E3FF;
    transition-delay: 0s,0.5s,1s,1s;
}

.button span{
    position: relative;
    z-index: 100;
}

.paddbot{
    padding-bottom: 3rem;
}

.colorhead{
    color: #0FD8D7;
}

.spacehead{
    margin-left: 5px;
}